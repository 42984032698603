export enum SETTLEMENT_METHOD {
  LIQUID_PARTNER_WALLET = "LIQUID_PARTNER_WALLET",
  LIQUID_USER_WALLET = "LIQUID_USER_WALLET",
  BLOCKCHAIN_TRANSFER = "BLOCKCHAIN_TRANSFER",
  FINASTRA_TRANSFER = "FINASTRA_TRANSFER",
}

export enum SETTLEMENT_ACCOUNT_FIELD {
  LIQUID_USED_ID = "LIQUID_USED_ID",
}

export enum SETTLEMENT_DIRECTION {
  FUNDING = "FUNDING",
  PAYOUT = "PAYOUT",
}
