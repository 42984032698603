export enum QEX_VIEW {
  LOADING = "loading",
  QUOTE_VIEW = "quoting",
  PAYOUT_VIEW = "payout",
  FUNDING_VIEW = "funding",
  SUCCESS_VIEW = "success",
  FAILURE_VIEW = "failure",
  COMPLETE_VIEW = "complete",
  COMING_SOON_VIEW = "coming-soon",
}
