
export enum POST_MESSAGES {
  SUGGESTED_SIZE = "suggested_size",
  STEP_TRANSITION = "step_transition",
  UI_EVENT = "ui_event",
  PERSIST_SESSION = "persist_session",
  DESTROY_SESSION = "destroy_session",

  // synthetic
  FINAL_SUCCESS = 'FINAL_SUCCESS',
  FINAL_FAILURE = 'FINAL_FAILURE'
}

export enum SEVERITY {
  INVALID = "INVALID",
  FATAL = "FATAL",
}

export enum UI_EVENT {
  BUTTON_CLICKED = "button_clicked",
}

export enum UI_EVENT_TARGET {
  QUOTE_VIEW_CCY_SELECTOR = "quote_view_ccy_selector",
}
