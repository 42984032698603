import { Func } from "../domain/general";

export class EventEmitter {
  listeners: { [event: string]: Array<Func> } = {};

  emit(event: string, payload: any) {
    if (this.listeners[event] !== undefined) {
      for (const f of this.listeners[event]) {
        f(payload);
      }
    }
  }
  on(event: string, f: Func) {
    if (Object.keys(this.listeners).length > 20) {
      throw new Error("Too many event listeners");
    }
    if (this.listeners[event] === undefined) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(f);
  }
}
