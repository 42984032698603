import React, { Component, createRef, RefObject } from "react";
import {
  DisplayError,
  QexWidgetApiConfig,
  QexWidgetApiSettings,
  StepTransition,
  Transaction,
  UiEvent,
} from "./domain";
import { QexWidgetApi } from "./util";
export * from "./index.umd";

interface QexWidgetProps {
  config: QexWidgetApiConfig;
  settings?: Partial<QexWidgetApiSettings>;
  onStepTransition?: (transition: StepTransition) => void;
  onSuccess?: (p: Transaction) => void;
  onError?: (p: Array<DisplayError>) => void;
  onUiEvent?: (p: UiEvent) => void;
  style?: React.CSSProperties;
  className?: string;
}

export class QexWidget extends Component<QexWidgetProps> {
  ref: RefObject<HTMLDivElement>;
  api?: QexWidgetApi;
  constructor(props: QexWidgetProps) {
    super(props);
    this.ref = createRef();
  }

  bindApi(div: HTMLDivElement, props: QexWidgetProps) {
    if (this.api !== undefined) {
      this.api.destroy();
    }
    this.api = new QexWidgetApi(div, props.settings);
    this.api.init(props.config);
    if (props.onStepTransition !== undefined) {
      this.api.onStepTransition(props.onStepTransition);
    }
    if (props.onSuccess !== undefined) {
      this.api.onSuccess(props.onSuccess);
    }
    if (props.onError !== undefined) {
      this.api.onError(props.onError);
    }
    if (props.onUiEvent !== undefined) {
      this.api.onUiEvent(props.onUiEvent);
    }
  }

  componentWillReceiveProps(newProps: QexWidgetProps) {
    if (this.ref !== null && this.ref.current !== null) {
      this.bindApi(this.ref.current, newProps);
    }
  }

  componentDidMount() {
    if (this.ref !== null && this.ref.current !== null) {
      this.bindApi(this.ref.current, this.props);
    }
  }

  render() {
    const { style, className } = this.props;
    return <div ref={this.ref} style={style} className={className}></div>;
  }
}
