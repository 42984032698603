import {
  CONFIG_VERSION,
  DEFAULT_SETTINGS,
  POST_MESSAGES,
  QEX_VIEW,
  UI_EVENT_TARGET,
} from "../const";
import {
  QexWidgetApiConfig,
  QexWidgetApiSettings,
  StepTransition,
  SuggestedSize,
  UiEvent,
} from "../domain";
import { EventEmitter } from "./event-emitter";

type func = (payload: any) => void;

export class QexWidgetApi {
  settings: QexWidgetApiSettings;
  config: QexWidgetApiConfig;
  iframe?: HTMLIFrameElement;
  elem: HTMLElement;
  eventEmitter = new EventEmitter();
  constructor(elem: HTMLElement, settings?: Partial<QexWidgetApiSettings>) {
    this.elem = elem;
    this.settings = { ...DEFAULT_SETTINGS, ...settings };

    this.eventEmitter.on(
      POST_MESSAGES.STEP_TRANSITION,
      this.handleStepTransition
    );

    this.eventEmitter.on(
      POST_MESSAGES.UI_EVENT,
      this.handleIframeOverflow      
    );
  }

  init = (config: QexWidgetApiConfig) => {
    if (config.config_version === undefined){
      config.config_version = CONFIG_VERSION;
    }
    this.config = config;
    this.iframe = document.createElement("iframe");
    this.iframe.allow = "camera; microphone";
    this.iframe.style.margin = "0";
    this.iframe.style.padding = "0";
    this.iframe.style.border = "0";
    this.iframe.style.fontSize = "100%";
    this.iframe.style.font = "inherit";
    this.iframe.style.width = "100%";
    this.iframe.style.height = this.settings.default_height;
    this.iframe.style.background = "transparent";
    this.iframe.style.overflow = "hidden";
    this.iframe.style.display = "block";
    this.iframe.style.margin = "0";
    this.iframe.style.marginTop = "10";
    this.iframe.style.position = "static";

    this.iframe.setAttribute("src", this.settings.plugin_url);
    this.elem.appendChild(this.iframe);
    this.elem.style.height = this.settings.default_height;

    window.addEventListener("message", this.handlePostMessage, false);

    this.iframe.onload = this.handleIframeLoaded;
  };
  /**
   * Trigger synthetic events
   * @param payload 
   */
  handleStepTransition = (payload: StepTransition) => {
    if (payload.new_step === QEX_VIEW.SUCCESS_VIEW) {
      this.eventEmitter.emit(POST_MESSAGES.FINAL_SUCCESS, payload);
    }
    if (
      payload.new_step === QEX_VIEW.FAILURE_VIEW ||
      payload.new_step === QEX_VIEW.COMING_SOON_VIEW
    ) {
      this.eventEmitter.emit(POST_MESSAGES.FINAL_FAILURE, payload);
    }
  }
  handleIframeLoaded = () => {
    if (this.iframe !== undefined && this.iframe.contentWindow !== null) {
      // initialize form
      this.iframe.contentWindow.postMessage(
        {
          event: "initialize",
          data: this.config,
        },
        "*"
      );
    }
  };
  handlePostMessage = (windowMessage: MessageEvent) => {
    const msg = windowMessage.data;
    switch (msg.event) {
      case POST_MESSAGES.SUGGESTED_SIZE:
        this.handleSuggestedSize(msg.data);
        break;

      default:
        this.eventEmitter.emit(msg.event, msg.data);
        break;
    }
  };
  handleSuggestedSize = (size: SuggestedSize) => {
    this.elem.style.height = size.backgroundHeight.toString() + "px";
    if (this.iframe !== undefined) {
      this.iframe.style.height = size.overflowHeight.toString() + "px";
    }
  };
  handleIframeOverflow = (payload: UiEvent) => {
    const { target, requestDetail } = payload;

    if (target === UI_EVENT_TARGET.QUOTE_VIEW_CCY_SELECTOR) {
      this.elem.style.overflow = requestDetail.isOpen ? 'visible' : 'hidden';
      // hack: On chrome, iframe doesn't properly update its DOM without browser action 
      this.elem.style.width = requestDetail.isOpen ? 'calc(100% - 1px)' : '100%';
    }
  };

  destroy() {
    if (this.iframe !== undefined) {
      this.iframe.remove();
    }
  }

  on(event: POST_MESSAGES, f: func) {
    this.eventEmitter.on(event, f);
  }

  onSizeSuggestion = (f: func) => {
    this.eventEmitter.on(POST_MESSAGES.SUGGESTED_SIZE, f);
  };

  onStepTransition = (f: func) => {
    this.eventEmitter.on(POST_MESSAGES.STEP_TRANSITION, f);
  };

  onUiEvent = (f: func) => {
    this.eventEmitter.on(POST_MESSAGES.UI_EVENT, f);
  };

  onSuccess = (f: func) => {
    this.eventEmitter.on(POST_MESSAGES.FINAL_SUCCESS, f);
  };

  onError = (f: func) => {
    this.eventEmitter.on(POST_MESSAGES.FINAL_FAILURE, f);
  };
}
