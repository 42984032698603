import { QEX_VIEW, SETTLEMENT_METHOD, THEME } from "../const";

export interface QexWidgetApiSettings {
  plugin_url: string;
  debug_logging: boolean;
  default_height: string;
}

export interface CustomAttributesConfig {
  color?: string;
  background?: string;
  "border-color"?: string;
}

export enum CUSTOM_STYLE {
  CsBase = "cs-base",
  CsButton = "cs-button",
  CsError = "cs-error",
  CsHighlight = "cs-highlight",
  CsInteractive = "cs-interactive",
  CsSelect = "cs-select",
  CsSpecial = "cs-special",
  CsSubtext = "cs-subtext",
}

export const CUSTOM_STYLES = Object.values(CUSTOM_STYLE);

export type CustomStylesConfig = {
  [cs in CUSTOM_STYLE]?: CustomAttributesConfig;
};

export interface InputParameters {
  account_key?: InputParameterField;
  transaction_key?: InputParameterField;
}

export interface InputParameterField {
  type: string;
  value: string;
}

export interface SettlementConfig {
  method?: SETTLEMENT_METHOD;
  currency?: string;
  quantity?: string;
  input_parameters?: InputParameters;
}

export interface SettlementDefault {
  currency?: string;
  quantity?: string;
}

export interface QexWidgetApiConfig {
  public_api_key: string;
  config_version?: string;
  user_locale?: string;
  theme?: THEME;
  identity?: {
    liquid_user_jwt: string;
  };
  render_views?: {
    [view in QEX_VIEW]: boolean;
  };
  custom_styles?: CustomStylesConfig;
  funding_default?: SettlementDefault;
  payout_default?: SettlementDefault;
  funding_settlement?: SettlementConfig;
  payout_settlement?: SettlementConfig;
  intent_id?: string;
  special_layout?: string;

  partner_order_id?: string;
  partner_ref?: string;
  partner_tags?: Array<string>;
  affiliate_id?: string;
  affiliate_ref?: string;
  affiliate_tags?: Array<string>;
}
